import Vue from "vue";
import App from "./App.vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { faPause } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faPlay, faPause);
Vue.component("font-awesome-icon", FontAwesomeIcon);

import "./index.css";
import "./assets/tailwind.css";
import "animate.css";

// import "./fullpage-extensions-bundle/parallax/fullpage.parallax.min";
import "./fullpage-extensions-bundle/fadingEffect/fullpage.fadingEffect.min";

import VueFullPage from "vue-fullpage.js";
import router from "./router";

Vue.use(VueFullPage);

import VueObserveVisibility from "vue-observe-visibility";
Vue.use(VueObserveVisibility);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
