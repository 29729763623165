import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(
        /* webpackChunkName: "intro" */ "../components/intro/IntroFullpageWrapper.vue"
      ),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/story-ana/",
    name: "StoryAna",
    component: () =>
      import(
        /* webpackChunkName: "story-ana" */ "../components/story1/Story1FullpageWrapper.vue"
      ),
  },
  {
    path: "/story-mila/",
    name: "StoryMila",
    component: () =>
      import(
        /* webpackChunkName: "story-mila" */ "../components/story3/Story3FullpageWrapper.vue"
      ),
  },
  {
    path: "/imprint",
    name: "Imprint",
    component: () =>
      import(/* webpackChunkName: "imprint" */ "../components/Imprint.vue"),
  },
  {
    path: "/credits",
    name: "Credits",
    component: () =>
      import(
        /* webpackChunkName: "imprint" */ "../components/credits/Credits.vue"
      ),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
