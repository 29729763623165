<template>
  <div id="app" class="container mx-auto">
    <NavbarBurgerMenu></NavbarBurgerMenu>
    <router-view />
  </div>
</template>

<script>
import NavbarBurgerMenu from "@/components/NavbarBurgerMenu";

export default {
  name: "App",
  components: { NavbarBurgerMenu },
  methods: {
    // See https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9
    calcWindowHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    },
  },
  created() {
    this.calcWindowHeight();
    window.addEventListener("resize", this.calcWindowHeight);
  },
};
</script>
